import { Stack, SxProps } from '@mui/material';
import { captureException } from '@sentry/react';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { deleteUpload } from '../../../../../Api';
import { getURLFromMediaItem } from '../../../../../Components/DraggableGallery/Media';
import { useUploader } from '../../../../onboarding/media-upload/use-uploader';
import { MediaDropzone } from './media-dropzone';
import MediaLoader from './media-loader';
import MediaPreview from './media-preview';

type MediaUploadProps = {
  experience: RegularExperience;
  handleMediaChange: (media: MediaItem[]) => void;
};

const MAX_FILES_LIMIT = 10;

export const Media = ({ experience, handleMediaChange }: MediaUploadProps) => {
  const [covers, setCovers] = useState(
    experience.media.cover?.slice(0, MAX_FILES_LIMIT) as MediaItem[]
  );
  const isDisabled = useMemo(() => covers.length >= MAX_FILES_LIMIT, [covers]);

  const { onDrop, filestoBeUploaded } = useUploader({
    id: experience.id,
    fileType: 'cover',
    modelType: 'experience',
    onUploaded: (medias) => {
      const newMedia = [...covers, ...medias];
      setCovers(newMedia);
      handleMediaChange(newMedia);
    },
  });

  const hasMedia = useMemo(
    () => Boolean(covers?.length) || Boolean(filestoBeUploaded.length),
    [covers, filestoBeUploaded]
  );

  const handleDelete = async (mediaItem: MediaItem) => {
    const filteredMedia = covers.filter(
      (media) => getURLFromMediaItem(media) !== getURLFromMediaItem(mediaItem)
    );

    try {
      setCovers(filteredMedia);
      handleMediaChange(filteredMedia);

      await deleteUpload(mediaItem);
    } catch (error) {
      captureException(error);
    }
  };

  const { getInputProps, open, isDragActive, getRootProps } = useDropzone({
    disabled: isDisabled,
    onDrop,
    maxFiles: MAX_FILES_LIMIT,
    useFsAccessApi: true,
    multiple: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.webp', '.gif'],
      'video/*': ['.mp4', '.mov', '.webm'],
    },
  });

  return (
    <Stack
      sx={{
        borderRadius: 2,
        backgroundColor: isDragActive
          ? lightTheme.palette.contrast.surface1
          : 'transparent',
        border: !isDragActive ? 'none' : null,
        borderColor: isDragActive
          ? lightTheme.palette.neutral.n200
          : 'transparent',
        borderStyle: 'dashed',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Stack
        sx={{
          gap: { xs: 1, md: 2 },
          overflowY: { xs: 'scroll', md: 'auto' },
          flexDirection: 'row',
          flexWrap: { xs: 'nowrap', md: 'wrap' },
          alignItems: 'stretch',
          scrollbarWidth: 'none',
          py: { xs: 2, md: 0 },
          '& > *': {
            width: hasMedia
              ? {
                  xs: 'calc(50% - 16px)', // 1/2 width on mobile
                  md: 'calc(20% - 16px)', // 1/5 width on desktop
                }
              : '100%',
            aspectRatio: hasMedia ? '1/1' : 'auto',
            flex: '0 0 auto',
          },
        }}
      >
        {/* Remove add media if limit exceeds */}
        {!isDisabled && <MediaDropzone open={open} hasMedia={hasMedia} />}
        {/* Don't include photos over MAX_FILES_LIMIT */}
        {covers.map((cover, index) => (
          <MediaPreview key={index} media={cover} handleDelete={handleDelete} />
        ))}
        {filestoBeUploaded.map((file) => (
          <MediaLoader key={file.tempUrl} file={file} />
        ))}
      </Stack>
    </Stack>
  );
};

export const defaultItemStyling: SxProps = {
  overflow: 'hidden',
  position: 'relative',
  gap: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
  backgroundColor: lightTheme.palette.contrast.surface1,
};
