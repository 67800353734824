import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC, PropsWithChildren } from 'react';

import { Badge } from '../../../../Components/badge/badge';

export const ElementList = ({ children }: PropsWithChildren) => {
  return (
    <Stack sx={{ gap: { xs: 1, sm: 1.5 }, marginTop: { xs: 1, sm: 0 } }}>
      {children}
    </Stack>
  );
};

const DEFAULT_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.contrast.white,
  borderColor: 'transparent',
  color: lightTheme.palette.contrast.black,
};

const ERROR_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.error.e100,
  borderColor: lightTheme.palette.error.e300,
  color: lightTheme.palette.error.e300,
};

export type ElementItemProps = PropsWithChildren<{
  onEdit: () => void;
  onDelete: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  hasError?: boolean;
  badgeValue?: string | null;
}>;

const ICON_SIZE_PX = 32;

export const ElementItem = ({
  onEdit,
  onDelete,
  Icon,
  hasError,
  children,
  badgeValue,
}: ElementItemProps) => {
  return (
    <ButtonBase
      disableRipple
      sx={{
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        boxShadow: lightTheme.shadows.medium,
        paddingY: 1.25,
        paddingLeft: 1,
        paddingRight: { xs: 1, sm: 2 },
        borderRadius: 1.25,
        borderWidth: 1,
        borderStyle: 'solid',
        cursor: 'pointer',
        transition: '0.1s ease-in-out',
        ':hover:not(:has(.delete:hover))': {
          backgroundColor: lightTheme.palette.contrast.surface1,
        },
        ':focus-visible': {
          outline: `1px solid ${lightTheme.palette.neutral.n500}`,
        },
        ...(hasError ? ERROR_ITEM_STYLE : DEFAULT_ITEM_STYLE),
      }}
      onClick={onEdit}
    >
      <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Stack
          sx={{
            gap: 1,
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '100%',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: lightTheme.palette.action.a100,
              height: ICON_SIZE_PX,
              width: ICON_SIZE_PX,
              minWidth: ICON_SIZE_PX,
              borderRadius: '50%',
              color: lightTheme.palette.action.a300,
            }}
          >
            <Icon fontSize="small" />
          </Stack>
          <Stack sx={{ minWidth: 0, flexGrow: 1 }}>{children}</Stack>
          {badgeValue && <Badge size="small">{badgeValue}</Badge>}
        </Stack>
      </Stack>
      <IconButton
        className="delete"
        sx={{
          color: lightTheme.palette.neutral.n300,
          height: ICON_SIZE_PX,
          width: ICON_SIZE_PX,
          minWidth: ICON_SIZE_PX,
          transition: 'color 0.1s',
          ':hover': { color: lightTheme.palette.neutral.n500 },
          ':focus-visible': {
            outline: `1px solid ${lightTheme.palette.neutral.n500}`,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <CloseOutlined fontSize="small" />
      </IconButton>
    </ButtonBase>
  );
};

type ElementItemContentProps = {
  value: string;
  description?: string;
  hasError?: boolean;
  errorPlaceholder?: string;
};
export const ElementItemContent: FC<ElementItemContentProps> = ({
  value,
  description,
  hasError,
  errorPlaceholder,
}) => {
  return (
    <>
      <EllipsedText fontSize="small" variant="medium" color="inherit">
        {hasError && errorPlaceholder ? errorPlaceholder : value}
      </EllipsedText>
      {description && (
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <EllipsedText
            fontSize="small"
            color={lightTheme.palette.neutral.n300}
          >
            {description}
          </EllipsedText>
        </Box>
      )}
    </>
  );
};

export const EllipsedText = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
