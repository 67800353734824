import { Stack, TextField } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, useSubmit } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateResourceTypeInputs } from './create-resource-type-inputs';

export const CreateResourceTypeForm: FC = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const formMethods = useForm<CreateResourceTypeInputs>({
    defaultValues: {
      name: '',
      description: '',
      capacity: 1,
      recoverTimeInSeconds: 0,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = async (data: CreateResourceTypeInputs) =>
    submit(data, { method: 'post', encType: 'application/json' });

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={Form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        gap={4}
        pt={2}
      >
        <TextField
          {...register('name', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="text"
          label={t('name', 'utils.generic')}
          placeholder={t('createNew.placeholder.name')}
          error={!!errors.name?.message}
          helperText={errors.name?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...register('description')}
          size="medium"
          fullWidth
          type="text"
          label={t('description')}
          placeholder={t('createNew.placeholder.description')}
          error={!!errors.description?.message}
          helperText={errors.description?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...register('capacity', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="number"
          label={t('capacity')}
          error={!!errors.capacity?.message}
          helperText={errors.capacity?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...register('recoverTimeInSeconds', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="number"
          label={t('recoverTime')}
          error={!!errors.recoverTimeInSeconds?.message}
          helperText={errors.recoverTimeInSeconds?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitting}
        >
          {t('createNew.submitLabel')}
        </Button>
      </Stack>
    </FormProvider>
  );
};
