import { Stack, Switch } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useCallback, useEffect, useState } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

export type AutoTranslateToggleProps = {
  isAutoTranslateEnabled: boolean;
  onChange: (enabled: boolean) => void;
};

export const AutoTranslateToggle = ({
  isAutoTranslateEnabled,
  onChange,
}: AutoTranslateToggleProps) => {
  const { t } = useTranslate('experience.edit.details.label');
  // If we solely rely on the value from the experience to show the
  // state of the toggle, we get a small delay when waiting for the action.
  // Using local state we can give instant feedback to the user.
  const [isEnabled, setIsEnabled] = useState(isAutoTranslateEnabled);

  useEffect(() => {
    setIsEnabled(isAutoTranslateEnabled);
  }, [isAutoTranslateEnabled]);

  const handleChange = useCallback(
    (checked: boolean) => {
      setIsEnabled(checked);
      onChange(checked);
    },
    [onChange]
  );

  return (
    <Stack
      onClick={() => handleChange(!isEnabled)}
      sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
    >
      <Text fontSize="small">{t('autoTranslate')}</Text>
      <Switch checked={isEnabled} size="small" sx={{ padding: 0.6 }} />
    </Stack>
  );
};
