import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';

import { DraggableVideoPreview } from '../../../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';
import { getURLFromMediaItem } from '../../../../../Components/DraggableGallery/Media';

type MediaPreviewProps = {
  media: MediaItem;
  handleDelete: (media: MediaItem) => void;
};

const ICON_SIZE_PX = 24;
export default function MediaPreview({
  media,
  handleDelete,
}: MediaPreviewProps) {
  return (
    <Stack
      sx={{
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <IconButton
        onClick={() => handleDelete(media)}
        sx={{
          position: 'absolute',
          width: ICON_SIZE_PX,
          height: ICON_SIZE_PX,
          top: 8,
          right: 8,
          zIndex: 10,
          color: lightTheme.palette.contrast.black,
          backgroundColor: lightTheme.palette.contrast.white,
        }}
      >
        <Close />
      </IconButton>
      {media.type === 'image' ? (
        <Box
          component="img"
          src={getURLFromMediaItem(media)}
          sx={{
            objectFit: 'cover',
            aspectRatio: '1 / 1',
          }}
        />
      ) : (
        <DraggableVideoPreview
          styles={videoJSStyles}
          options={{
            autoplay: true,
            loop: true,
            muted: true,
            sources: [
              {
                src: getURLFromMediaItem(media),
                type: 'application/x-mpegURL',
              },
            ],
          }}
        />
      )}
    </Stack>
  );
}

const videoJSStyles = {
  parent: {},
  video: {
    width: '100%',
    height: '100%',
  },
};
