import { ErrorOutlineOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Select,
  Stack,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode } from 'react';

type CustomSelectProps = {
  selectedValue: string | string[];
  options: CustomMenuItemProps[];
  footerOptions?: CustomMenuItemProps[];
  onChange: (value: string | string[]) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  multiple?: boolean;
  emptyLabel?: string;
  icon?: ReactNode;
  error?: boolean;
  helperText?: string;
};

/**
 * @param options - If you pass a `undefined` value it wont show up as selected
 * @param footerOptions - If you pass a `undefined` value it wont show up as selected
 */

export const CustomSelect = ({
  selectedValue,
  options,
  footerOptions,
  onChange,
  onClose,
  onOpen,
  open,
  multiple = false,
  emptyLabel,
  icon,
  error,
  helperText,
}: CustomSelectProps) => {
  return (
    <Stack sx={{ gap: 0.5 }}>
      <Select
        multiple={multiple}
        value={selectedValue}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(e) => {
          const value = e.target.value;
          if (Array.isArray(value)) {
            const filteredValues = value.filter((v) => v !== undefined);
            onChange(filteredValues);
          } else {
            onChange(value);
          }
        }}
        variant="standard"
        disableUnderline
        sx={{
          maxHeight: 32,
          '& .MuiSvgIcon-root': {
            right: 8,
          },
        }}
        IconComponent={(props) => (
          <ExpandMoreOutlined {...props} fontSize="small" />
        )}
        MenuProps={{
          PaperProps: {
            sx: { borderRadius: 2 },
          },
        }}
        inputProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            maxHeight: 32,
            borderRadius: 1,
            ':focus-visible': {
              borderRadius: 1,
            },
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: error ? lightTheme.palette.error.e300 : 'transparent',
            color: error
              ? lightTheme.palette.error.e300
              : lightTheme.palette.neutral.n400,
            backgroundColor: error
              ? lightTheme.palette.error.e100
              : lightTheme.palette.contrast.surface1,
            padding: 1,
            pr: '28px !important',
          },
        }}
        displayEmpty
        renderValue={(value) => (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            {(Array.isArray(value) && multiple) || !value
              ? icon
              : options.find((option) => option.value === value)?.icon}
            <Text fontSize="small" variant="medium" color="inherit">
              {renderSelectValue(value, options, multiple, emptyLabel)}
            </Text>
          </Stack>
        )}
        size="small"
      >
        {options.map(({ label, ...props }, index) => (
          <CustomMenuItem key={label + index} label={label} {...props} />
        ))}
        {Array.isArray(footerOptions)
          ? footerOptions.map(({ label, children, ...props }, index) => (
              <CustomMenuItem
                key={label + index}
                label={label}
                sx={{
                  borderTop: index === 0 ? 1 : 0,
                  borderColor: lightTheme.palette.neutral.n100,
                }}
                {...props}
              >
                {children}
              </CustomMenuItem>
            ))
          : footerOptions}
      </Select>
      {helperText && (
        <Text fontSize="xsmall" color={lightTheme.palette.error.e400}>
          {helperText}
        </Text>
      )}
    </Stack>
  );
};

const renderSelectValue = (
  value: CustomSelectProps['selectedValue'],
  options: CustomSelectProps['options'],
  multiple: CustomSelectProps['multiple'],
  emptyLabel: CustomSelectProps['emptyLabel']
) => {
  if (!value || value.length === 0) return emptyLabel;

  if (Array.isArray(value) && multiple) {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    return options.find((option) => option.value === value[0])?.label;
  }

  return options.find((option) => option.value === value)?.label;
};

interface CustomMenuItemProps extends MenuItemProps {
  label: string;
  icon?: ReactNode;
  hasError?: boolean;
}

function CustomMenuItem({
  label,
  icon,
  children,
  hasError,
  sx,
  ...props
}: CustomMenuItemProps) {
  return (
    <MenuItem
      disableRipple
      sx={{
        paddingX: 1.5,
        paddingY: 1,
        ...sx,
      }}
      focusVisibleClassName={undefined}
      {...props}
    >
      {children ?? (
        <Stack sx={{ flexDirection: 'row', flexGrow: 1 }}>
          {icon && (
            <ListItemIcon
              sx={{ minWidth: 'unset !important', marginRight: 1.5 }}
            >
              {icon}
            </ListItemIcon>
          )}
          <Text fontSize="small">{label}</Text>
          {hasError && (
            <ErrorOutlineOutlined
              sx={{ marginLeft: 'auto', color: lightTheme.palette.error.e300 }}
            />
          )}
        </Stack>
      )}
    </MenuItem>
  );
}
