import { Box } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { FC } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

type Props = {
  resourceType: ResourceType;
  locations: Promise<Array<Location>>;
};

export const ResourceTypeDetailsCard = ({ resourceType }: Props) => {
  const { t } = useTranslate('resourceManagement');

  return (
    <Box
      display="grid"
      px={4}
      py={3}
      gap={1.5}
      gridTemplateColumns="1fr 1fr"
      sx={{
        backgroundColor: lightTheme.palette.contrast.white,
      }}
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius={1}
    >
      <GridRow label={t('general.capacity')} value={resourceType.capacity} />
      <GridRow
        label="Recover time (needs translation)"
        value={t('countMinutes', 'utils.generic.units', {
          count: (resourceType.recoverTimeInSeconds ?? 0) / 60,
        })}
      />
    </Box>
  );
};

type GridRowProps = { label: string; value: string | number };
const GridRow: FC<GridRowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
          {label}
        </Text>
      </Box>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" variant="medium">
          {value}
        </Text>
      </Box>
    </>
  );
};
