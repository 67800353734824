import NiceModal from '@ebay/nice-modal-react';
import {
  AddOutlined,
  LanguageOutlined,
  LocationOnOutlined,
  LockOutlined,
} from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import { Location } from '@understory-io/utils-types';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useYupForm } from '../../../Hooks/use-yup-form';
import { Language } from '../../../i18n/config';
import { CreateLocationDialog } from '../../../Sections/StorefrontSections/location-management/dialogs/create-location-dialog';
import {
  type BasicExperienceFields as BasicExperienceFieldsType,
  basicExperienceFieldsSchema,
} from '../schemas/basicExperienceFieldsSchema';
import { CustomSelect } from './components/custom-select';
import { PublishErrors } from './edit-experience';

type BasicExperienceFieldsProps = {
  locations: Location[];
  defaultValues: BasicExperienceFieldsType;
  onSubmit: (data: BasicExperienceFieldsType) => void;
  publishErrors?: PublishErrors;
  activeLanguage: Language;
};

const DEBOUNCE_SUBMIT_MS = 500;

export const BasicExperienceFields = ({
  locations,
  defaultValues,
  onSubmit,
  publishErrors,
  activeLanguage,
}: BasicExperienceFieldsProps) => {
  const { t } = useTranslation();
  const formMethods = useYupForm({
    schema: basicExperienceFieldsSchema,
    defaultValues,
  });
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: {
      errors: { locationIds: locationIdsError, visibility: visibilityError },
    },
    trigger,
  } = formMethods;

  const { field: visibility } = useController({
    name: `visibility`,
    control,
    rules: { required: t('experience.edit.basic.visibility.required') },
  });

  const { field: locationIds } = useController({
    name: `locationIds`,
    control,
    rules: { required: 'experience.edit.basic.visibility.required' },
  });

  const handleVisibilityChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    visibility.onChange(newValue);
  };

  const handleCreateLocation = async () => {
    setIsLocationOpen(false);
    const locationId = await NiceModal.show(CreateLocationDialog);
    if (locationId && typeof locationId === 'string') {
      locationIds.onChange([...locationIds.value, locationId]);
    }
  };

  useEffect(() => {
    // Debounce submission of form
    const debouncedSubmit = debounce(
      // We still want to submit on handleSubmit's onInvalid handler
      handleSubmit(onSubmit, () => onSubmit(getValues())),
      DEBOUNCE_SUBMIT_MS
    );
    // Subscribe to form changes
    const subscription = watch(() => debouncedSubmit());

    return () => subscription.unsubscribe();
  }, [getValues, handleSubmit, onSubmit, watch]);

  // Trigger validation on mount
  useEffect(() => {
    if (
      publishErrors?.general?.find((error) => error.key === 'headline') ||
      publishErrors?.[activeLanguage]?.find((error) => error.key === 'headline')
    ) {
      trigger();
    }
  }, [activeLanguage, publishErrors, trigger]);

  const visibilityOptions = {
    public: {
      value: 'public',
      label: t('experience.card.visibility.public'),
      icon: <LanguageOutlined fontSize="small" />,
    },
    private: {
      value: 'private',
      label: t('experience.card.visibility.private'),
      icon: <LockOutlined fontSize="small" />,
    },
  };

  const locationFooterOptions = [
    {
      label: t('location.dialog.create.title'),
      onClick: handleCreateLocation,
      icon: <AddOutlined fontSize="small" />,
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" noValidate sx={{ gap: 4 }}>
        <TitleInput />
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            alignItems: 'flex-start',
          }}
        >
          <CustomSelect
            selectedValue={visibility.value}
            options={Object.values(visibilityOptions)}
            onChange={handleVisibilityChange}
            error={!!visibilityError}
          />
          <CustomSelect
            multiple
            selectedValue={locationIds.value}
            open={isLocationOpen}
            onClose={() => setIsLocationOpen(false)}
            onOpen={() => setIsLocationOpen(true)}
            icon={<LocationOnOutlined fontSize="small" />}
            options={locations.map(({ locationId, locationName }) => ({
              value: locationId,
              label: locationName,
              icon: <LocationOnOutlined fontSize="small" />,
            }))}
            footerOptions={locationFooterOptions}
            onChange={locationIds.onChange}
            emptyLabel={t(
              'location.dialog.delete.replaceLocation.selectLocation'
            )}
            error={
              !!locationIdsError ||
              !!publishErrors?.general?.find(
                (error) => error.key === 'locationIds'
              )
            }
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

const TitleInput = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: {
      errors: { headline: headlineError },
    },
  } = useFormContext<BasicExperienceFieldsType>();

  const { field: headline } = useController({
    name: 'headline',
    control,
  });

  return (
    <TextField
      multiline
      value={headline.value}
      onChange={(e) => headline.onChange(e.target.value)}
      autoFocus={!headline.value}
      variant="standard"
      placeholder={t('experience.edit.basic.headline.placeholder')}
      inputProps={{ sx: { fontSize: 44, lineHeight: '100%' } }}
      error={!!headlineError}
      helperText={
        headlineError?.message
          ? t(headlineError.message, { count: 50 })
          : undefined
      }
    />
  );
};
