import {
  InfoOutlined,
  LayersOutlined,
  SentimentSatisfiedOutlined,
  SubjectOutlined,
  TagOutlined,
} from '@mui/icons-material';
import { Grid, Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { TranslateFunction, useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';
import { hasLocalizedValue } from './utils/form-helpers';
import { useNavigateWithParams } from './utils/use-navigate-with-params';

type MoreOptionsProps = {
  experience: RegularExperience;
};

const optionsConfig = (
  experienceId: string,
  t: TranslateFunction
): Record<
  string,
  {
    key: string;
    Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string;
    };
    title: string;
    description: string;
    path: string;
  }
> => ({
  description: {
    key: 'description',
    Icon: SubjectOutlined,
    title: t('label.description'),
    description: t('description.description'),
    path: routes.experience.details(experienceId).edit.description,
  },
  tags: {
    key: 'tags',
    Icon: TagOutlined,
    title: t('label.tags'),
    description: t('description.tags'),
    path: routes.experience.details(experienceId).edit.tags,
  },
  guestInfo: {
    key: 'guest-info',
    Icon: SentimentSatisfiedOutlined,
    title: t('label.guestInfo'),
    description: t('description.guestInfo'),
    path: routes.experience.details(experienceId).edit.guestInfo.index,
  },
  practicalInfo: {
    key: 'practical-info',
    Icon: InfoOutlined,
    title: t('label.practicalInfo'),
    description: t('description.practicalInfo'),
    path: routes.experience.details(experienceId).edit.practicalInfo,
  },
  resources: {
    key: 'resources',
    Icon: LayersOutlined,
    title: t('label.resources'),
    description: t('description.resources'),
    path: routes.experience.details(experienceId).edit.resources.create,
  },
});

export const MoreOptions = ({ experience }: MoreOptionsProps) => {
  const { t } = useTranslate('experience.edit.options');
  const navigateWithParams = useNavigateWithParams();

  const flags = useFlags();

  const availableOptions = useMemo(() => {
    const options = [];

    const config = optionsConfig(experience.id, t);

    if (!hasLocalizedValue(experience.practicalInfo)) {
      options.push(config.description);
    }

    if (!experience.tagIds.length) {
      options.push(config.tags);
    }

    if (!hasLocalizedValue(experience.infoForGuests?.yes)) {
      options.push(config.practicalInfo);
    }

    if (!experience.informationRequests?.length) {
      options.push(config.guestInfo);
    }

    if (
      flags.menuItemResourceManagement &&
      (experience.resourceRules?.rules.length ?? 0) === 0
    ) {
      options.push(config.resources);
    }

    return options;
  }, [experience, t, flags.menuItemResourceManagement]);

  if (availableOptions.length === 0) return null;

  return (
    <Stack sx={{ gap: 1.5 }}>
      <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
        {t('title')}
      </Text>
      <Grid container spacing={2}>
        {availableOptions.map(({ key, path, ...props }) => (
          <Grid item key={key} xs={6} sm={4}>
            <OptionItem {...props} onClick={() => navigateWithParams(path)} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

type OptionItemProps = {
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  title: string;
  description: string;
  onClick: () => void;
};

const OptionItem = ({ Icon, title, description, onClick }: OptionItemProps) => {
  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'space-between',
        cursor: 'pointer',
        backgroundColor: lightTheme.palette.contrast.surface1,
        borderRadius: 2,
        padding: 2,
        gap: 1,
        transition: 'background-color 0.1s',
        ':hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
      }}
      onClick={onClick}
    >
      <Icon sx={{ color: lightTheme.palette.neutral.n400 }} />
      <Stack sx={{ gap: 0.25 }}>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {title}
        </Text>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};
